<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { Alpha2LanguageCode, LaravelResponse } from "~/types";

const languages = useLanguages();

await callOnce(async () => {
  const response = await useAsyncData(() =>
    $larafetch<LaravelResponse<{ items: { label: string; value: number; alpha2: Alpha2LanguageCode }[] }>>("options/languages")
  );
  languages.value = response.data.value.items;
});

useHead({
  htmlAttrs: {
    lang: "en-US",
    class: "h-full",
  },
  bodyAttrs: {
    class: "flex h-full flex-col font-sans text-base text-paragraph antialiased",
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Approach Guides` : "Approach Guides";
  },
});
</script>
